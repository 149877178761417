














































































































































import CharacterLength from "@/components/CharacterLength/CharacterLength.vue";
import { debounce } from "@/helpers/debounce";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseWorkOrderDetail } from "@/models/interface/logistic.interface";
import {
  PickingListTransactionData,
  RequestPickingList,
  RequestPickingListLine,
} from "@/models/interface/salesOrder.interface";
import { SalesOrderResponseDto } from "@/models/interface/sales-order";
import { logisticServices } from "@/services/logistic.service";
import { salesOrderServices } from "@/services/salesorder.service";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import moment from "moment";
import printJs from "print-js";
import Vue from "vue";
import Component from "vue-class-component";
import TransactionTypeSelect, {
  TRANSACTION_TYPE,
} from "./TransactionTypeSelect.vue";

@Component({
  components: {
    "transaction-type-select": TransactionTypeSelect,
    CharacterLength,
  },
})
export default class PickingList extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  moment = moment;
  page = 0 as number;
  limit = 10 as number;
  isFormSubmitted = false as boolean;
  search = "" as string;
  mode = "" as string;
  columnsTable = [
    {
      title: this.$t("lbl_number_short"),
      dataIndex: "no",
      key: "no",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      key: "productCode",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      key: "productName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_merk"),
      dataIndex: "merk",
      key: "merk",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_serial_number_product"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      key: "type",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "location",
      key: "location",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "qty",
      key: "qty",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_qty_pick"),
      dataIndex: "qtyPick",
      key: "qtyPick",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_qty_available"),
      dataIndex: "qtyAvailable",
      key: "qtyAvailable",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "uom",
      key: "uom",
      scopedSlots: { customRender: "nullable" },
    },
  ];
  form!: WrappedFormUtils;
  formRules = {
    transactionType: {
      label: "lbl_transaction_type",
      name: "transactionType",
      placeholder: "lbl_transaction_type_placeholder",
      decorator: [
        "transactionType",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    transactionRefNo: {
      label: "lbl_transaction_ref_no",
      name: "documentNumber",
      placeholder: "lbl_transaction_ref_no_placeholder",
      decorator: [
        "documentNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_customer_name_placeholder",
      decorator: [
        "customerName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    transactionsReferenceDate: {
      label: "lbl_transaction_reference_date",
      name: "transactionsReferenceDate",
      placeholder: "lbl_transaction_reference_date_placeholder",
      decorator: [
        "transactionsReferenceDate",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    pickingListNo: {
      label: "lbl_picking_list_no",
      name: "pickingListNo",
      placeholder: "lbl_picking_list_no_placeholder",
      decorator: [
        "pickingListNo",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    recipient: {
      label: "lbl_recipient",
      name: "recipient",
      placeholder: "lbl_recipient_placeholder",
      decorator: [
        "recipient",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    personInCharge: {
      label: "lbl_person_in_charge",
      name: "personInCharge",
      placeholder: "lbl_person_in_charge_placeholder",
      decorator: [
        "personInCharge",
        {
          rules: [],
        },
      ],
    },
    description: {
      label: "lbl_description",
      name: "description",
      placeholder: "lbl_description_placeholder",
      decorator: [
        "description",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  loading = {
    print: false,
    transactions: false as boolean,
    submit: false as boolean,
  };
  disable = {
    transactionType: false as boolean,
    transactionRefNo: false as boolean,
    recipient: false as boolean,
    description: false as boolean,
    submit: false as boolean,
    print: true as boolean,
    pic: false as boolean,
  };
  transactionId = "" as string;
  transactionType = "" as TRANSACTION_TYPE;
  transactionsList = [] as PickingListTransactionData[];
  detailWorkOrder = {} as ResponseWorkOrderDetail;
  detailSalesOrder = {} as SalesOrderResponseDto;
  pickingListLine = [] as any[];
  title = "lbl_create_picking_list";

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  mounted() {
    this.getTransactions("");
    switch (this.mode) {
      case Mode.EDIT:
        this.disableAllFields();
        this.getDetailPickingList();
        this.disable.print = false;
        this.title = "lbl_view_picking_list";
        break;
      case Mode.CREATE:
        this.disable.transactionRefNo = true;
        this.disable.transactionType = false;
        this.disable.recipient = false;
        this.disable.description = false;
        this.disable.submit = false;
        this.title = "lbl_create_picking_list";
        break;
      default:
        break;
    }
  }

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "pickingList" });
  }

  created() {
    this.mode = this.$route.meta.mode;
  }

  disableAllFields() {
    this.disable.transactionRefNo = true;
    this.disable.transactionType = true;
    this.disable.recipient = true;
    this.disable.description = true;
    this.disable.submit = true;
    this.disable.pic = true;
  }

  getDetailPickingList(): void {
    salesOrderServices
      .getDetailPickingList(this.$route.params.id)
      .then(data => {
        this.transactionsList.push({
          id: data.transcationRefNo,
          documentNumber: data.transcationRefNo,
          key: 0,
        });
        this.form.setFieldsValue({
          personInCharge: data.personInCharge,
          customerName: data.customerName,
          transactionsReferenceDate: data.transactionRefDate
            ? moment(data.transactionRefDate).format(DEFAULT_DATE_FORMAT)
            : "",
          description: data.description,
          pickingListNo: data.pickingListNo,
          transactionType: data.transactionType,
          documentNumber: this.transactionsList[0].id,
          recipient: data.recipient,
        });
        this.pickingListLine = data.pickingListLines.map((e, i) => {
          return {
            no: i + 1 + ".",
            key: i,
            partNumber: e.partNumber,
            serialNumber: e.serialNumber,
            type: e.type,
            merk: e.merk,
            productCode: e.productCode,
            productName: e.productName,
            uom: e.uom,
            qty: e.qty,
            qtyAvailable: e.qtyAvailable,
            qtyPick: e.qtyPick,
            location: e.warehouseLocationDesc,
            locationId: e.locationId,
            productId: e.productId,
            uomId: e.uomId,
            assetId: e.assetId,
            assetParentId: e.assetParentId,
          };
        });
      });
  }

  getTransactions(value: string): void {
    let params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (this.transactionType === TRANSACTION_TYPE.WO) {
      if (value) {
        params.search = `documentNumber~*${value}*`;
      }
      this.loading.transactions = true;
      logisticServices
        .getAvailableWorkOrder(params)
        .then(data => {
          this.transactionsList = data.data
            .map((dataObject, index) => {
              return {
                id: dataObject.workOrderId,
                documentNumber: dataObject.workOrderNumber,
                key: index,
              };
            })
            .filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);
        })
        .finally(() => (this.loading.transactions = false));
    } else if (this.transactionType === TRANSACTION_TYPE.SO) {
      if (value) {
        params.search = `documentNumber~*${value}*`;
      }
      this.loading.transactions = true;
      salesOrderServices
        .getAvailableSalesOrder(params)
        .then(data => {
          this.transactionsList = data.data
            .map((dataObject, index) => {
              return {
                id: dataObject.salesOrderId,
                documentNumber: dataObject.salesOrderNo,
                key: index,
              };
            })
            .filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);
        })
        .finally(() => (this.loading.transactions = false));
    }
  }

  searchTransaction(value: string): void {
    debounce(() => {
      this.getTransactions(value);
    });
  }

  onChangeTransaction(value: string): void {
    this.transactionId = value;
    if (this.transactionType === TRANSACTION_TYPE.WO) {
      this.loading.transactions = true;
      logisticServices
        .getDetailWorkOrder(value)
        .then(data => {
          this.form.setFieldsValue({
            personInCharge: data.picName,
            customerName: data.customerName,
            transactionsReferenceDate: data.workOrderDate
              ? moment(data.workOrderDate).format(DEFAULT_DATE_FORMAT)
              : "",
          });
          this.pickingListLine = data.workOrderLines.map((e, i) => {
            return {
              no: i + 1 + ".",
              key: i,
              partNumber: e.partNumber,
              serialNumber: e.serialNumber,
              type: e.type,
              merk: e.productMerk,
              productCode: e.productCode,
              productName: e.productName,
              uom: e.productUomName,
              qty: currencyFormat(e.qtyWorkOrder ? e.qtyWorkOrder : 0),
              qtyAvailable: currencyFormat(e.qtyAvailable ? e.qtyAvailable : 0),
              qtyPick: " ",
              location: e.productLocationDesc,
              locationId: e.productLocationId,
              productId: e.productId,
              uomId: e.productUomId,
              assetId: e.assetId,
            };
          });
        })
        .finally(() => (this.loading.transactions = false));
    } else if (this.transactionType === TRANSACTION_TYPE.SO) {
      this.loading.transactions = true;
      salesOrderServices
        .getDetailSalesOrder(value)
        .then(data => {
          this.form.setFieldsValue({
            personInCharge: "-",
            customerName: data.customerName,
            transactionsReferenceDate: data.date
              ? moment(data.date).format(DEFAULT_DATE_FORMAT)
              : "",
          });
          this.pickingListLine = data.salesOrderLines.map((e, i) => {
            return {
              no: i + 1 + ".",
              key: i,
              partNumber: e.partNumber,
              serialNumber: e.serialNumber,
              type: e.type,
              merk: e.merk,
              productCode: e.productCode ? e.productCode : e.unitCode,
              productName: e.productName ? e.productName : e.assetNo,
              assetId: e.assetId,
              uom: e.uomName,
              qty: currencyFormat(e.qty ? e.qty : 0),
              qtyAvailable: " ",
              qtyPick: " ",
              location: e.location,
              locationId: e.locationId,
              productId: e.productId,
              uomId: e.uomId,
              assetParentId: e.assetParentId,
            };
          });
        })
        .finally(() => (this.loading.transactions = false));
    }
  }

  onChangeTransactionType(value: string): void {
    switch (value) {
      case "Sales Order":
        this.transactionType = TRANSACTION_TYPE.SO;
        break;
      case "Work Order":
        this.transactionType = TRANSACTION_TYPE.WO;
        break;
      default:
        break;
    }
    this.disable.transactionRefNo = false;
    this.getTransactions("");
  }

  handleSubmit(): void {
    this.form.validateFields((errors, values) => {
      if (errors) {
        this.$notification.error({
          description: "Please check your field",
          message: "Error",
          duration: 30,
        });
      } else {
        const request = {} as RequestPickingList;
        const requestLine = [] as RequestPickingListLine[];
        //construct payload
        request.description = values.description;
        request.personInCharge = values.personInCharge;
        request.recipient = values.recipient;
        request.transactionType = values.transactionType;
        if (this.transactionType === TRANSACTION_TYPE.WO) {
          request.woId = this.transactionId;
        } else if (this.transactionType === TRANSACTION_TYPE.SO) {
          request.soId = this.transactionId;
        }
        this.pickingListLine.forEach(e => {
          requestLine.push({
            assetId: e.assetId as string,
            locationId: e.locationId as string,
            location: e.location,
            partNumber: e.partNumber,
            productId: e.productId as string,
            qty: changeCurrencytoNumeric(e.qty),
            qtyAvailable: changeCurrencytoNumeric(
              e.qtyAvailable ? e.qtyAvailable : 0
            ),
            qtyPick: changeCurrencytoNumeric(e.qtyPick ? e.qtyPick : 0),
            type: e.type,
            uom: e.uom,
            serialNumber: e.serialNumber,
            assetParentId: e.assetParentId,
          });
        });
        request.lineRequestDTOList = requestLine;
        this.loading.submit = true;
        salesOrderServices
          .createPickingList(request)
          .then(doc => {
            this.$notification.success({
              message: Messages.CREATE_SUCCESS,
              description: `Picking List Number: ${doc}`,
              duration: 0,
            });
            this.$router.push("/sales/salestransactions/pickinglist");
          })
          .finally(() => (this.loading.submit = false));
      }
    });
  }

  handlePrint(): void {
    this.loading.print = true;
    salesOrderServices
      .getReportPrintPickingList([this.$route.params.id])
      .then(data => {
        const url = window.URL.createObjectURL(new Blob([data]));
        printJs(url);
        window.URL.revokeObjectURL(url);
      })
      .finally(() => (this.loading.print = false));
  }

  handleBack(): void {
    this.$confirm({
      title: "Are you sure want to back?",
      onOk: () => {
        this.form.resetFields();
        this.$router.push("/sales/salestransactions/pickinglist");
      },
      onCancel() {
        return;
      },
    });
  }
}
