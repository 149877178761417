var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t(_vm.title) } },
    [
      _c(
        "a-form",
        {
          attrs: {
            layout: "vertical",
            form: _vm.form,
            "label-col": { span: 8 },
            "wrapper-col": { span: 14 }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.transactionType.label)
                      }
                    },
                    [
                      _c("transaction-type-select", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.transactionType.decorator,
                            expression: "formRules.transactionType.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.transactionType.name,
                          disabled: _vm.disable.transactionType
                        },
                        on: { change: _vm.onChangeTransactionType }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.transactionRefNo.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.transactionRefNo.decorator,
                              expression: "formRules.transactionRefNo.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.transactionRefNo.name,
                            placeholder: _vm.$t(
                              _vm.formRules.transactionRefNo.placeholder
                            ),
                            showSearch: true,
                            allowClear: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            disabled: _vm.disable.transactionRefNo
                          },
                          on: {
                            search: function(value) {
                              return _vm.searchTransaction(value)
                            },
                            change: _vm.onChangeTransaction
                          }
                        },
                        _vm._l(_vm.transactionsList, function(item, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: item.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s(item.documentNumber) + " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(item.documentNumber) + " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.customerName.label) }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.customerName.decorator,
                            expression: "formRules.customerName.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.customerName.name,
                          placeholder: _vm.$t(
                            _vm.formRules.customerName.placeholder
                          ),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          _vm.formRules.transactionsReferenceDate.label
                        )
                      }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value:
                              _vm.formRules.transactionsReferenceDate.decorator,
                            expression:
                              "formRules.transactionsReferenceDate.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.transactionsReferenceDate.name,
                          placeholder: _vm.$t(
                            _vm.formRules.transactionsReferenceDate.placeholder
                          ),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.pickingListNo.label)
                      }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.pickingListNo.decorator,
                            expression: "formRules.pickingListNo.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.pickingListNo.name,
                          placeholder: _vm.$t(
                            _vm.formRules.pickingListNo.placeholder
                          ),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.recipient.label) } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.recipient.decorator,
                            expression: "formRules.recipient.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.recipient.name,
                          placeholder: _vm.$t(
                            _vm.formRules.recipient.placeholder
                          ),
                          autocomplete: "off",
                          disabled: _vm.disable.recipient
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.description.label) }
                    },
                    [
                      _c("a-textarea", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.description.decorator,
                            expression: "formRules.description.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.description.name,
                          placeholder: _vm.$t(
                            _vm.formRules.description.placeholder
                          ),
                          autocomplete: "off",
                          disabled: _vm.disable.description,
                          rows: 4
                        }
                      }),
                      _c("character-length", {
                        attrs: {
                          value: _vm.form.getFieldValue("description") || ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                attrs: {
                  columns: _vm.columnsTable,
                  dataSource: _vm.pickingListLine,
                  pagination: {
                    showTotal: function(total) {
                      return _vm.$t("lbl_total_items", { total: total })
                    }
                  },
                  size: "small",
                  scroll: { x: 1000 }
                },
                scopedSlots: _vm._u([
                  {
                    key: "nullable",
                    fn: function(text) {
                      return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                    }
                  },
                  {
                    key: "number",
                    fn: function(text) {
                      return _c("span", {}, [
                        _vm._v(_vm._s(_vm._f("qty")(text)))
                      ])
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        disabled: _vm.disable.print,
                        loading: _vm.loading.print,
                        type: "primary"
                      },
                      on: { click: _vm.handlePrint }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                  ),
                  _vm.$can("create", "picking-list")
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.submit,
                            disabled: _vm.disable.submit
                          },
                          on: { click: _vm.handleSubmit }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }